<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
      :title="$t('nav.assign-orders')"


    />

    <!--    :btn-title="$t('vehicles.new_btn')"-->

    <!--    :btn="true"-->
    <!--    @btnAction="showModal = true;modalData=null"-->
    <div>
      <filter-header
        :content='filterContent'
        :show-filters='false'
        @applyFilter='applyFilter'
        @resetFilter='resetFilter'
        @orderApplicants='orderData'

      />
    </div>
    <section
      class='
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      '>
      <div class='d-flex flex-wrap flex-1'>
        <b-col lg='12' md='12' class='px-0'>

          <div class='w-table d-flex flex-column'>
            <w-tables
              :noDataTitle="$t('table.no_data_title')"
              :noDataSubtitle="$t('table.no_data_subtitle')"
              v-if='getData'
              :per-page='filterData.per_page'
              :items='getData'
              :fields='tableFields'
              :custimized-items="[
                { name: 'action' },
              ]"
            >


              <template slot='action' slot-scope='{ data }'>

                <b-dropdown
                  variant='background'
                  class='table-option'
                  toggle-class='text-decoration-none rounded-10 px-2 py-2 '
                  menu-class='rounded-8'
                  no-caret
                  dropleft v-if='data.item.type==null'
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon />
                  </template>

                  <b-dropdown-item
                    :to="toToDetail(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary permission-show-detail">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>


                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg='12' md='12' class='px-0'>
          <wameed-pagination
            v-if='getTotal'
            v-model='filterData.page'
            :page='filterData.page'
            :total-items='getTotal.totalItems'
            :per_page='filterData.per_page'
            :menu-title="$t('common.show_results')"
            @changePage='changePage'
            @changeCurrentPage='changeCurrentPage'
          />
        </b-col>

      </div>

    </section>


  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import WarningModal from '@/components/WarningModal';

import { WameedPagination, WameedTables as WTables } from 'wameed-ui/dist/wameed-ui.esm';


export default {
  components: {
    WarningModal,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WTables
  },
  data() {
    return {
      showModal: false,
      modalData: null,

      showToggleModal: false,
      toggleModal: {
        title: this.$i18n.t('payments.toggle.inactive.title'),
        subTitle: this.$i18n.t('payments.toggle.inactive.sub_title'),
        btn: this.$i18n.t('payments.toggle.inactive.btn')
      },
      toggleData: { id: 0, status: 0 },
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: ''
      },
      filterContent: [],
      tableFields: [
        {
          key: 'index',
          label: '#',
          sortable: false

        },
        {
          key: 'no',
          label: this.$i18n.t('assign-orders.no'),
          sortable: false,
          tdClass: 'w-2/5'
        },
        {
          key: 'name',
          label: this.$i18n.t('assign-orders.name'),
          sortable: false,
          tdClass: 'w-2/5'
        },
        {
          key: 'active_count',
          label: this.$i18n.t('assign-orders.active_count'),
          sortable: false,
          tdClass: 'w-2/5'
        },
        {
          key: 'completed_count',
          label: this.$i18n.t('assign-orders.completed_count'),
          sortable: false,
          tdClass: 'w-2/5'
        },
        {
          key: 'canceled_count',
          label: this.$i18n.t('assign-orders.canceled_count'),
          sortable: false,
          tdClass: 'w-2/5'
        },

        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/5'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getData: 'admin/assignOrders/getData',
      getTotal: 'admin/assignOrders/getTotal'
    })
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      _getData: 'admin/assignOrders/get'
    }),


    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page: 1
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event
      };
      this.loadData();
    },

    loadData() {

      this._getData(this.filterData);
    },


    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item
      };
      this.loadData();
    },


    toToDetail(id) {
      return {
        name: 'order-detail',
        params: {lang: this.$i18n.locale, id},
      };
    },
  }

};
</script>
